import * as React from "react";
import { HeadFC } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { DesktopLayoutBasicColumn } from "../../components/layout/desktop-layouts/desktop-layout-basic-column";
import { ProgramWindow } from "../../components/gui/organisms/program-window/program-window";
import { PostContent } from "../../components/gui/atoms/post-content";
import { postBlurb, postTitle } from "./msdos-compilation";
import styled from "styled-components";
import { SiteTransitionLink } from "../../components/layout/transitions/site-transition-link";
import { DesktopId } from "../../app/desktops";
import {
  postTextShadowAwfulBlurb,
  postTextShadowAwfulTitle,
} from "./text-shadow-is-awful-in-chrome-edge";

const StyledHeaderClusterBack = styled.div`
  margin-bottom: -20%;
  @media (min-width: 600px) {
    max-width: 1200px;
    margin-left: 10%;
    margin-bottom: -42%;
  }
`;

const StyledHeaderClusterFront = styled.div<{ align: "start" | "end" }>`
  max-width: 400px;
  align-self: center;
  margin-left: 5%;
  margin-right: 5%;
  @media (min-width: 600px) {
    align-self: ${(props) =>
      props.align === "start" ? "flex-start" : "flex-end"};
    margin-left: 0%;
    margin-right: 10%;
    margin-top: -60px;
  }
`;

const PostsPage = () => {
  return (
    <DesktopLayoutBasicColumn
      desktopId={DesktopId.POSTS}
      canWindowsRearrange={true}
    >
      <StyledHeaderClusterBack>
        <ProgramWindow
          windowId="posts-all-msdos-bkg"
          title=""
          applyContentPadding={false}
          defaultGeometry={{
            zIndexPriority: -1,
          }}
        >
          <StaticImage
            src="../../images/dalle/art/floppy-computer-01.png"
            alt="Painting of floppy disks, compact discs, and an old computer monitor with its circuitry exposed, generated by DALLE-2"
          />
        </ProgramWindow>
      </StyledHeaderClusterBack>
      <StyledHeaderClusterFront align="start">
        <ProgramWindow windowId="posts-all-msdos-fg" title="">
          <PostContent>
            <h1>
              <SiteTransitionLink to="/posts/msdos-compilation">
                {postTitle}
              </SiteTransitionLink>
            </h1>
            {postBlurb}
            <p>
              <SiteTransitionLink to="/posts/msdos-compilation">
                Read more
              </SiteTransitionLink>
            </p>
          </PostContent>
        </ProgramWindow>
      </StyledHeaderClusterFront>
      {/* TODO: refactor to arrange multiple posts nicely */}
      {/* TODO: add artwork for text shadow post */}
      <StyledHeaderClusterFront align="end">
        <ProgramWindow windowId="posts-all-text-shadow-fg" title="">
          <PostContent>
            <h1>
              <SiteTransitionLink to="/posts/text-shadow-is-awful-in-chrome-edge">
                {postTextShadowAwfulTitle}
              </SiteTransitionLink>
            </h1>
            {postTextShadowAwfulBlurb}
            <p>
              <SiteTransitionLink to="/posts/text-shadow-is-awful-in-chrome-edge">
                Read more
              </SiteTransitionLink>
            </p>
          </PostContent>
        </ProgramWindow>
      </StyledHeaderClusterFront>
      {/* TODO: refactor this page and remove this random spacer */}
      <div style={{ height: 100 }} />
    </DesktopLayoutBasicColumn>
  );
};

export default PostsPage;

export const Head: HeadFC = () => <title>Posts | simontang.dev</title>;
